module.exports = function headroomNav() {

    $('.menu li a').removeAttr('title');

    $(window).scroll(function () {

        var TopWindow = $(this).scrollTop();

        // console.log(TopWindow);

        if (TopWindow > 210) {
            $('body').addClass('header-not-at-top');
        }
        if (TopWindow <= 1) {
            $('body').removeClass('header-not-at-top');
        }

    });



}

