const mobileNav = require('./assets/mobileNav')
//const searchForm = require('./assets/searchForm')
//const owlCarousel = require('./assets/owl.carousel')
const headroomNav = require('./assets/headroomNav')
const css = require('./scss/style.scss')

// mobileNav for responsive navigation
mobileNav()

// Headroom nav for fixed navigation on scroll
headroomNav()

// Expanding searchFrom on click
//searchForm()

// Agenda Homepage carousel
//owlCarousel()

// Add an IE-agent class to body if IE browsers
if (navigator.userAgent.match(/Trident.*rv\:11\./) || navigator.userAgent.match(/MSIE/) || navigator.userAgent.match(/Edge/)) {
    $('body').addClass('IE-agent');
}
