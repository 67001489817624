module.exports = function mobileNav() {


    /*****************  Navigation du site pour les mobiles *****************/

    $('.nav-trigger').on('click', function (event) {
        event.preventDefault();
        $('body').toggleClass('nav-open');
        $('.main-navigation').toggleClass('menu-open');
    });

    /************* Closes the Responsive Menu on Menu Item Click *****************/

    $('.main-navigation a').click(function () {

        if ($('.main-navigation').hasClass('menu-open')) {
            $('.nav-trigger').click();
        }

    });

}